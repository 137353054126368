:root {
    --color-dark-gold: #28241f;
    --color-light-gold: #f7f6f1;
    --color-gold: #bd9e66;
}

.nav-bar-bottom .tab-has-label {
    background-color: var(--color-dark-gold);
}

.tab-selected .cls-1 {
    fill: var(--color-selected);
}

.modal-default section, .modal-default video {
    width: 200px;
    height: 200px;
}